import { request, API } from '@/utils/request'

//角色详情
// export function getRolesInfo(id) {
//   return request({
//     url: API.host + `/api/role/info/${id}`,
//     method: 'get'
//   })
// }

//删除角色
export function deleteRoles(id) {
  return request({
    url: API.host + `/api/users-permissions/roles/${id}`,
    method: 'delete'
  })
}

//添加角色
export function addRoles(data) {
  return request({
    url: API.host + '/api/users-permissions/roles',
    method: 'post',
    data: data || {}
  })
}
//修改角色
export function updateRole(data) {
  return request({
    url: API.host + `/api/users-permissions/roles/${data.id}`,
    method: 'put',
    data: data || {}
  })
}

//获取角色列表
export function getRolesList(data) {
  return request({
    url: API.host + '/api/users-permissions/roles',
    method: 'get'
  })
}
//查看个人信息
export function userMeInfo(id) {
  return request({
    url: API.host + '/api/users/me?populate=*',
    method: 'get'
  })
}
//角色详情-新
export function getRolesInfo(id) {
  return request({
    url: API.host + `/api/users-permissions/roles/${id}`,
    method: 'get'
  })
}

