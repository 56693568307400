/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */


export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

export function getNameStr(list, val) {
	let nameStr;
	list.forEach((item, index) => {
		if (item.value == val) {
			nameStr = item.label;
		}
	});
	return nameStr;
}

export function setConfig(obj, isCopy) {
	console.log(obj,obj.assets);
	let nameObj = {
		ar: {
			modes: obj.ar.modes,
			camera: obj.ar.camera
		},
		tips: obj.tips,
		camera: {
			control: {
				zoom: obj.scale?1:0, //是否可缩放
				// "move":0, //是否可移动
				drag: obj.drag?1:0,
				rotate: {
					x: obj.rotate?1:0, //是否x轴可旋转
					y: obj.rotate?1:0 //是否Y轴可旋转
				}
			},
			position: {
				x: 0,
				y: 0,
				z: 0
			},
			'clear-color': "0 0 0", //清除颜色
			near: 0.1, // 近裁剪平面
			far: 1000 // 远裁剪平面
		},
		env: obj.env,
		light: obj.light,
		gltf: obj.assets.filter((item)=>{
			return item.attributes.type == 'Model';
		}).map((el)=>{
			console.log(el,"=====================")
			return {
				id: el.id,
				url: el.attributes.src.data.attributes.url,
				display: 'trigger',
				mask: el.attributes.mask || null,
				position: el.attributes.position,
				rotation: el.attributes.rotation,
				scale: el.attributes.scale,
			}
		}),
		audios: [
		    {
				url: obj.music.media?obj.music.media.url:'',
				volume: obj.music.volume,
				loop: obj.music.loop,
				auto: obj.music.autoplay,
				long: true
			}
		]
	};
	if( obj.type==='Plane' ){
		nameObj.tips = {
			msg: nameObj.tips.msg,
			marker: {
				msg: nameObj.tips.marker.msg
			}
		}
		nameObj.ar.planeMode = obj.ar.planeMode;
	}else if( obj.type==='Image' ){
		nameObj.tips = {
			msg: nameObj.tips.msg
		}
	}else if( obj.type==='Object' ){
		nameObj.tips = {
			msg: nameObj.tips.msg
		}
	}else if( obj.type==='Landmark' ){
		nameObj.tips = {
			msg: nameObj.tips.msg,
			img: nameObj.tips.img.url
		}
	}

	// if( obj.type!='Plane' ){
		//非识别的动画控制
		let noTracked = obj.animation_control.filter((item)=>{
			return item.triger.content != "tracked";
		});
		console.log(noTracked,'===================');
		let noTrackedIds = noTracked.map((item)=>{
			return item.triger.content;
		});
		let markerArr = [];
		let trackedArr = obj.animation_control.filter((item)=>{
			return item.triger.content == "tracked";
		});
    if(isCopy) {
      trackedArr.forEach((el)=>{
        let imageItem = obj.assets.filter((img)=>{
          el.triger.imageId = img.id
          return img.id == el.triger.imageId;
        });
        let gltfItem = nameObj.gltf.filter((gltf)=>{
          console.log('=======', gltf.id, el.animation.value[0], '=======')
           el.animation.value[0] = gltf.id
          return gltf.id == el.animation.value[0];
        });
        if( gltfItem.length==0 ){
          alert(`模型被删，请及时排查问题或重新设置动画控制`);
        }
        let animationConfig = {
          tracked: {
            value: 'true',
            animationName: el.animation.value[1],
            animationState: el.animation.event,
            animationLoop: el.animation.loop?'0':'1'
          },
          clicked: {},
          stop: {
            value: "false",
            animationModelGroup: []
          },
          play: {
            value: "false",
            animationModelGroup: []
          }
        };
        console.log(noTrackedIds,el.animation.value[0],'+++++++++++++++++++++++');
        if( noTrackedIds.includes(el.animation.value[0]) ){
          animationConfig.clicked = {
            value: 'true',
            animationModelGroup: noTracked.filter((item)=>{
              return item.triger.content == el.animation.value[0];
            }).map((child)=>{
              return {
                modelID: child.animation.value[0],
                animationName: child.animation.value[1],
                animationState: child.animation.event,
                animationLoop: child.animation.loop?'0':'1'
              }
            })
          }
        }
        let trigger = [
          {
            type: 'gltf',
            id: el.animation.value[0],
            mask: gltfItem[0].mask || null,
            data: obj.type=='Plane'?{"cast-shadow": true}:null,
            position: gltfItem[0].position,
            rotation: gltfItem[0].rotation,
            scale: {
              x: gltfItem[0].scale,
              y: gltfItem[0].scale,
              z: gltfItem[0].scale
            },
            animationConfig: animationConfig,
          }
        ]
        noTracked.filter((item)=>{
          return item.triger.content == el.animation.value[0];
        }).forEach((child)=>{
          console.log(child.animation.value[0],"-------------------------------");
          nameObj.gltf.forEach((small)=>{
            if( small.id==child.animation.value[0] ){
              trigger.push({
                type: 'gltf',
                id: small.id,
                mask: small.mask || null,
                data: obj.type=='Plane'?{"cast-shadow": true}:null,
                position: small.position,
                rotation: small.rotation,
                scale: {
                  x: small.scale,
                  y: small.scale,
                  z: small.scale
                },
              });
            }
          });
        });
        let markerItem = {
          type: obj.type=='Image'?'2d':obj.type=='Object'?'3d':obj.type=='Landmark'?'2d':'',
          src: imageItem.length==1?imageItem[0].attributes.src.data.attributes.url:'图片是不是被删了',
          trigger: trigger
        };
        if( obj.type!='Plane'&&imageItem.length==0 ){
          alert(`${obj.type=='Image'?'识别图':'map文件'}被删，请及时排查问题或重新设置动画控制`);
        }
        let isSameUrl = false;
        markerArr.forEach((item)=>{
          console.log(markerItem.trigger,item.trigger,'+++++++++===============++++++++++');
          if( item.src==markerItem.src ){
            isSameUrl = true;
            item.trigger = [...item.trigger,...markerItem.trigger];
          }
        });
        if( isSameUrl===false ){
          markerArr.push(markerItem);
        }
      })
    } else {
      trackedArr.forEach((el)=>{
        let imageItem = obj.assets.filter((img)=>{
          return img.id == el.triger.imageId;
        });
        let gltfItem = nameObj.gltf.filter((gltf)=>{
          console.log(gltf.id, el.animation.value[0])
          return gltf.id == el.animation.value[0];
        });
        if( gltfItem.length==0 ){
          alert(`模型被删，请及时排查问题或重新设置动画控制`);
        }
        let animationConfig = {
          tracked: {
            value: 'true',
            animationName: el.animation.value[1],
            animationState: el.animation.event,
            animationLoop: el.animation.loop?'0':'1'
          },
          clicked: {},
          stop: {
            value: "false",
            animationModelGroup: []
          },
          play: {
            value: "false",
            animationModelGroup: []
          }
        };
        console.log(noTrackedIds,el.animation.value[0],'+++++++++++++++++++++++');
        if( noTrackedIds.includes(el.animation.value[0]) ){
          animationConfig.clicked = {
            value: 'true',
            animationModelGroup: noTracked.filter((item)=>{
              return item.triger.content == el.animation.value[0];
            }).map((child)=>{
              return {
                modelID: child.animation.value[0],
                animationName: child.animation.value[1],
                animationState: child.animation.event,
                animationLoop: child.animation.loop?'0':'1'
              }
            })
          }
        }
        let trigger = [
          {
            type: 'gltf',
            id: el.animation.value[0],
            mask: gltfItem[0].mask || null,
            data: obj.type=='Plane'?{"cast-shadow": true}:null,
            position: gltfItem[0].position,
            rotation: gltfItem[0].rotation,
            scale: {
              x: gltfItem[0].scale,
              y: gltfItem[0].scale,
              z: gltfItem[0].scale
            },
            animationConfig: animationConfig,
          }
        ]
        noTracked.filter((item)=>{
          return item.triger.content == el.animation.value[0];
        }).forEach((child)=>{
          console.log(child.animation.value[0],"-------------------------------");
          nameObj.gltf.forEach((small)=>{
            if( small.id==child.animation.value[0] ){
              trigger.push({
                type: 'gltf',
                id: small.id,
                mask: small.mask || null,
                data: obj.type=='Plane'?{"cast-shadow": true}:null,
                position: small.position,
                rotation: small.rotation,
                scale: {
                  x: small.scale,
                  y: small.scale,
                  z: small.scale
                },
              });
            }
          });
        });
        let markerItem = {
          type: obj.type=='Image'?'2d':obj.type=='Object'?'3d':obj.type=='Landmark'?'2d':'',
          src: imageItem.length==1?imageItem[0].attributes.src.data.attributes.url:'图片是不是被删了',
          trigger: trigger
        };
        if( obj.type!='Plane'&&imageItem.length==0 ){
          alert(`${obj.type=='Image'?'识别图':'map文件'}被删，请及时排查问题或重新设置动画控制`);
        }
        let isSameUrl = false;
        markerArr.forEach((item)=>{
          console.log(markerItem.trigger,item.trigger,'+++++++++===============++++++++++');
          if( item.src==markerItem.src ){
            isSameUrl = true;
            item.trigger = [...item.trigger,...markerItem.trigger];
          }
        });
        if( isSameUrl===false ){
          markerArr.push(markerItem);
        }
      })
    }

		if( obj.type==='Plane' ){
			nameObj.gltf.push({id: obj.anchor.gltf.id.id,url: obj.anchor.gltf.id.url,display: "trigger"});
			nameObj.anchor = {
				id: obj.anchor.gltf.id.id,
				place: markerArr.length>0?markerArr[0].trigger:[]
			}
			nameObj.anchor.place.push({
				type: "mesh",
				id: "mesh",
				data: {
					"receive-shadow": true
				},
				position: {
					"x": 0,
					"y": 1,
					"z": 0
				},
				rotation: {
					"x": 0,
					"y": 0,
					"z": 0
				},
				scale: {
					"x": obj.shade_area,
					"y": obj.shade_area,
					"z": obj.shade_area
				}
			});
		}else{
			nameObj.marker = markerArr;
		}
	// }
	return nameObj;
}
