import { request, API } from '@/utils/request';

//获取列表
export function getList() {
  return request({
    url: API.host + '/api/users',
    method: 'get'
  })
}

//数据同步
export function userSync(data) {
  return request({
    url: API.host + '/api/user/sync',
    method: 'get'
  })
}

//查看详情
export function userInfo(id) {
  return request({
    url: API.host + `/api/users/${id}`,
    method: 'get'
  })
}

//绑定角色
export function bangDingRole(userId, roleId) {
  return request({
    url: API.host + `/api/users/${userId}`,
    method: 'put',
    data: {role: roleId} || {}
  })
}

//用户详情-旧
export function getRolesInfo(id) {
  return request({
    url: API.host + `/api/role/info/${id}`,
    method: 'get'
  })
}

//删除用户
export function deleteUserInfo(id) {
  return request({
    url: API.host + `/api/users/${id}`,
    method: 'delete'
  })
}

//新增用户
export function createUserInfo(data) {
  return request({
    url: API.host + `/api/auth/local/register`,
    method: 'post',
    data: data || {}
  })
}

//修改用户
export function updateUserInfo(data) {
  return request({
    url: API.host + `/api/users/${data.id}`,
    method: 'put',
    data: data || {}
  })
}


export function commonRequest(data) {
  return request({
    url: API.host + API[data.name],
    method: 'post',
    data: data.data || {},
    showloading: data.showloading
  })
}
export function commonRequestGET(data) {
  return request({
    url: API.host + API[data.name],
    method: 'get',
    params: data.data || {},
    showloading: data.showloading
  })
}
export function downLoad(data) {
  return request({
    url: API.host + API[data.name],
    method: 'get',
    data: data.data || {},
    responseType: 'blob',
    showloading: data.showloading
  })
}

export function sendCode(data) {
  return request({
    url: API.host + '/login/code',
    method: 'get',
    params: data || {}
  })
}
