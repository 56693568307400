import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { showLoading, hideLoading } from './loading'
import { getToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

// const origin = process.env.VUE_APP_REQUEST
var origin = ''
var env = process.env.NODE_ENV

//环境切换配置
if(env == 'production'){
  origin = 'https://api.intoxosmo.com'
}else{
  origin = 'https://api.intoxosmo.com'
}

// 接口地址
const API = {
  host: origin,
  //user-controller
  login:'/user/singin',//登录接口
  setAccess:'/user/setAccess',//设置用户访问密钥
}

// create an axios instance
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
request.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.showloading) {
      showLoading()
    }
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    // config.headers['Token'] = Cookies.get('Admin-Token')
	// config.headers['Authorization'] = 'Bearer 67308e596adbb5d2767327744a22ae021ecb544832ceefc9330629e2baca4b1907b9412a3441d3d63a509e2554d7b5d0c00d554a221d7996195b0cb8f034cb78487b015e3032b23a3f26e6763fe4dd2c14d90477420c7273f2c39eec10be233ec8b99160c341aa79056e312f24423a3cba3fb365b6c780ddade4e1f5fc18edf1'
	return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    hideLoading()
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.

    if (res.status == 200 || !res.status) {
      return Promise.resolve(res)
    } else {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
	console.log(error.message,110) // for debug
    debugger
    if (error.message.indexOf("401") > -1||error.message.indexOf("403") > -1) {
		removeToken();
		location.href = location.origin + '/login';
		return
    }
    hideLoading()
    Message({message: error.message,type: 'error',duration: 5 * 1000})
    return Promise.reject(error)
  }
)

export {
  request,
  API
}
